<template>
  <div v-loading="loading">
    <div class="qrCodeDiv">
      <div>
        <p style="font-size: 18px">Android</p>
        <canvas id="QRCode_Android_Download2" style="width: 280px; height: 280px"></canvas>
      </div>
      <div>
        <p style="font-size: 18px">IOS</p>
        <canvas id="QRCode_IOS_Download2" style="width: 280px; height: 280px"></canvas>
      </div>
    </div>
    <div v-if="this.show === 1" class="flowdiagram_zh">
      <H2>App操作流程——中文版</H2>
      <p>1、下载安装APP后，打开app，点击登录输入账号及密码登录经销商或代办处。入下图所示：</p>
      <img src="@/assets/images/flowdiagram_zh/1.png" alt="">
      <p>2、登录成功后我的菜单页面点击财务管理</p>
      <img src="@/assets/images/flowdiagram_zh/2.png" alt="">
      <p>点击充值管理输入充值金额及密码点击执行即提交成功，经财务审核成功后即充值入账。</p>
      <img src="@/assets/images/flowdiagram_zh/3.png" alt="">
      <p>3、点击账户补货历史即可查看充值记录。</p>
      <img src="@/assets/images/flowdiagram_zh/4.png" alt="">
      <p>4、财务管理下点击提取资金，输入金额及密码点击执行即提交提现申请；经财务审成功后即提现成功。</p>
      <img src="@/assets/images/flowdiagram_zh/5.png" alt="">
      <p>5、点击提款历史即可查看提现记录。</p>
      <img src="@/assets/images/flowdiagram_zh/6.png" alt="">
      <p>6、财务管理下点击货币兑换</p>
      <img src="@/assets/images/flowdiagram_zh/7.png" alt="">
      <p>选择币种输入金额及密码点击执行即货币兑换成功，无需财务审核，提交即成功。</p>
      <img src="@/assets/images/flowdiagram_zh/8.png" alt="">
      <p>7、点击货币兑换历史即可查看货币兑换流水记录</p>
      <img src="@/assets/images/flowdiagram_zh/9.png" alt="">
      <p>8、点击财务管理下的资金转账</p>
      <img src="@/assets/images/flowdiagram_zh/10.png" alt="">
      <p>选择账户，输入入账用户，金额及密码，点击执行即转账成功</p>
      <img src="@/assets/images/flowdiagram_zh/11.png" alt="">
      <p>9、点击转账历史即可查看转账记录</p>
      <img src="@/assets/images/flowdiagram_zh/12.png" alt="">
      <p>10、点击财务管理下的现金流量，即可查看当前登录账号的所有流水记录</p>
      <img src="@/assets/images/flowdiagram_zh/13.png" alt="">
      <p>11、点击财务管理下的重消奖明细选择月份即可查看当前登录账号获得重消奖明细</p>
      <img src="@/assets/images/flowdiagram_zh/14.png" alt="">
      <p>12、点击财务管理下的实发总奖金即可查看当前登录账号获得奖金</p>
      <img src="@/assets/images/flowdiagram_zh/15.png" alt="">
      <p>13、点击我的菜单下经销商注册</p>
      <img src="@/assets/images/flowdiagram_zh/16.png" alt="">
      <p>
        注册方法1：选择经销商注册填写注册信息、选择状态推荐及安置人并选择放至位置及收货信息并设置注册经销商密码，点击保存。</p>
      <img src="@/assets/images/flowdiagram_zh/17.png" alt="">
      <p>在首页或者商品页选择产品加入购物车；</p>
      <img src="@/assets/images/flowdiagram_zh/18.png" alt="">
      <img src="@/assets/images/flowdiagram_zh/19.png" alt="">
      <p>点击购物车进行注册时选择状态对应的级别PV产品下单，最终下单PV不低于注册时所选状态PV；
        可点击放弃新注册首购即取消报单；
        可点击继续购买再次选择产品进行加购；
        也可点击下单进行继续注册：</p>
      <img src="@/assets/images/flowdiagram_zh/20.png" alt="">
      <p>点击下单后跳转至支付页面；
        可点击取消首购单不注册；
        支付可选择立即支付或稍后支付；输入支付密码点击支付后即注册报单成功。</p>
      <img src="@/assets/images/flowdiagram_zh/21.png" alt="">
      <p>注册方法2：注册分享：输入推荐及安置人点击保存</p>
      <img src="@/assets/images/flowdiagram_zh/22.png" alt="">
      <p>点击保存后即生成二维码，手机扫描二维码进行注册</p>
      <img src="@/assets/images/flowdiagram_zh/23.png" alt="">
      <p>
        14、购买门票：我的菜单中点击经销商注册选择购买门票，门票数量点击下一步后输入经销商编号及支付密码点击下单即报单成功。</p>
      <img src="@/assets/images/flowdiagram_zh/24.png" alt="">
      <img src="@/assets/images/flowdiagram_zh/25.png" alt="">
      <p>
        15、升级单、重消单：登录app后首页或商品页选择产品加入购物车；购物车页面默认为当前登录登录账号报单，也可输入其他经销商编号进行报单；</p>
      <img src="@/assets/images/flowdiagram_zh/26.png" alt="">
      <p>订单类型为升级单，需选择级别点击下单进行报单；</p>
      <img src="@/assets/images/flowdiagram_zh/27.png" alt="">
      <p>订单类型为重消单，点击下单进行报单；</p>
      <img src="@/assets/images/flowdiagram_zh/28.png" alt="">
      <p>点击下单后需选择收货方式及输入支付密码进行支付。</p>
      <img src="@/assets/images/flowdiagram_zh/29.png" alt="">
      <p>支付成功即报单成功。</p>
      <img src="@/assets/images/flowdiagram_zh/30.png" alt="">
      <p>16、我的菜单点击订购历史即可查看订单及门票购买记录。</p>
      <img src="@/assets/images/flowdiagram_zh/31.png" alt="">
      <p>点击订单详情可查看订单明细</p>
      <img src="@/assets/images/flowdiagram_zh/32.png" alt="">
      <p>17、网体查询：我的菜单下账户管理菜单可查看经销商推荐及安置树形及图形网体</p>
      <img src="@/assets/images/flowdiagram_zh/33.png" alt="">
      <p>推荐网图形：点击即可查看经销商推荐网下情况</p>
      <img src="@/assets/images/flowdiagram_zh/34.png" alt="">
      <p>推荐网-树形：可查看经销商网体及个人业绩情况</p>
      <img src="@/assets/images/flowdiagram_zh/35.png" alt="">
      <p>安置网图形：点击即可查看经销商安置网下情况；</p>
      <img src="@/assets/images/flowdiagram_zh/36.png" alt="">
      <p>安置网树形：点击即可查看经销商安置网下情况</p>
      <img src="@/assets/images/flowdiagram_zh/37.png" alt="">
      <p>18、个人信息修改：点击我的菜单下个人信息修改进行修改当前登录会员密码及收货地址点击保存提交成功。</p>
      <img src="@/assets/images/flowdiagram_zh/38.png" alt="">
      <p>19、计步器：登录后进入我的菜单，点击计步器点击允许App获取手机健康数据即可查看步数。</p>
      <img src="@/assets/images/flowdiagram_zh/39.png" alt="">
      <p>20、退出登录：我的菜单点击会员退出即退出登录；</p>
      <img src="@/assets/images/flowdiagram_zh/40.png" alt="">
      <p>或个人信息修改页面点击会员退出即退出登录。</p>
      <img src="@/assets/images/flowdiagram_zh/41.png" alt="">
    </div>
    <div v-if="this.show === 2">
      <h2>App - rus</h2>
      <p>1、После того, как приложение было загружено, открыть приложение, нажать «Войти» чтобы ввести данные аккаунта и
        пароль для входа в систему. Как показано на картинке ниже:</p>
      <img src="@/assets/images/flowdiagram_rus/1.png" alt="">
      <p>2、После успешного входа, в меню выбрать и нажать пункт «Управление финансами».</p>
      <img src="@/assets/images/flowdiagram_rus/2.png" alt="">
      <p>Выберите и нажмите «Управление пополнением», введите сумму пополнения и пароль, после чего нажмите «Готово»,
        после чего пополнение будет успешно произведено. После проверки сумма будет успешно списана и зачислена на
        указанный счёт.</p>
      <img src="@/assets/images/flowdiagram_rus/3.png" alt="">
      <p>3、Нажмите на «История пополнения счёта» чтобы просмотреть историю пополнений.</p>
      <img src="@/assets/images/flowdiagram_rus/4.png" alt="">
      <p>4、Выберите и нажмите «Управление финансами», чтобы вывести средства. Введите сумму и пароль, нажмите
        «Выполнить» чтобы отправить заявку на выведение средств; после проверки вывод средств будет успешно
        выполнен. </p>
      <img src="@/assets/images/flowdiagram_rus/5.png" alt="">
      <p>5、Нажмите на «История снятия средств» чтобы просмотреть историю вывода средств. </p>
      <img src="@/assets/images/flowdiagram_rus/6.png" alt="">
      <p>6、Выберите и нажмите «Обмен валюты» в разделе «Управление финансами».</p>
      <img src="@/assets/images/flowdiagram_rus/7.png" alt="">
      <p>Выберите валюту, введите сумму и пароль, нажмите «Выполнить», и конвертация валюты будет успешно выполнена
        сразу же после запроса.</p>
      <img src="@/assets/images/flowdiagram_rus/8.png" alt="">
      <p>7、Выберите и нажмите на «История обмена валюты», чтобы просмотреть историю свершённых операций обмена
        валюты.</p>
      <img src="@/assets/images/flowdiagram_rus/9.png" alt="">
      <p>8、Выберите и нажмите на «Перевод средств» в разделе «Управление финансами»</p>
      <img src="@/assets/images/flowdiagram_rus/10.png" alt="">
      <p>Выберите учётную запись, введите имя пользователя, желаемую сумму и пароль, после чего нажмите «Выполнить»
        чтобы завершить перевод средств. </p>
      <img src="@/assets/images/flowdiagram_rus/11.png" alt="">
      <p>9、Выберите и нажмите на «История переводов» чтобы просмотреть историю совершённых переводов средств.</p>
      <img src="@/assets/images/flowdiagram_rus/12.png" alt="">
      <p>10、Выберите и нажмите на «Движение денежных средств» в разделе «Управление финансами» для того, чтобы
        просмотреть историю всех выполненных действий в учётной записи.</p>
      <img src="@/assets/images/flowdiagram_rus/13.png" alt="">
      <p>11、Выберите и нажмите на «Информация о вознаграждении за повторную покупку» в разделе «Финансовое управление» и
        выберите месяц, чтобы просмотреть информацию о вознаграждении за повторную покупку, полученном для текущей
        учетной записи.</p>
      <img src="@/assets/images/flowdiagram_rus/14.png" alt="">
      <p>12、Выберите и нажмите на «Общий бонус» в разделе «Управление финансами» чтобы просмотреть текущую учётную
        запись для получения бонуса.</p>
      <img src="@/assets/images/flowdiagram_rus/15.png" alt="">
      <p>13、В Меню выберите и нажмите пункт «Регистрация дистрибьютора»</p>
      <img src="@/assets/images/flowdiagram_rus/16.png" alt="">
      <p>№1 Способ регистрации: Выберите дистрибьютора для регистрации и введите все необходимые регистрационные данные;
        Выберите статус рекомендации и расположение, информацию о получении товара, а также установите пароль для
        зарегистрированного дистрибьютора, после чего нажмите «Сохранить». </p>
      <img src="@/assets/images/flowdiagram_rus/17.png" alt="">
      <p>На главной странице или на странице с продукцией выберите продукт, чтобы добавить его в корзину.</p>
      <img src="@/assets/images/flowdiagram_rus/18.png" alt="">
      <img src="@/assets/images/flowdiagram_rus/19.png" alt="">
      <p>Для продолжения регистрации нажмите на корзину, выберите соответствующий по PV продукт, чтобы оформить заказ.
        При этом сумма по PV не должна быть меньше суммы PV статуса, выбранного при регистрации. </p>
      <img src="@/assets/images/flowdiagram_rus/20.png" alt="">
      <p>Вы также можете нажать на «Отменить регистрацию первичной покупки» чтобы отменить заказ.</p>
      <p>Вы можете нажать «Продолжить покупки» чтобы снова выбрать продукт для покупки. </p>
      <p>Так же вы можете нажать на «Оформить заказ» чтобы продолжить регистрацию.</p>
      <p>Нажмите на «Сделать заказ» чтобы перейти на страницу оплаты;</p>
      <p>Вы можете нажать на «Отменить первичный заказ» чтобы прервать регистрацию;</p>
      <p>Вы можете совершить оплату прямо на месте или оплатить покупку позже; Введите платёжный пароль и нажмите
        «Оплатить» чтобы успешно завершить регистрацию. </p>
      <img src="@/assets/images/flowdiagram_rus/21.png" alt="">
      <p>№2 Способ регистрации: поделитесь реферальной ссылкой: Введите информацию о рекомендателе и выберите
        расположение, после чего нажмите «Сохранить»</p>
      <img src="@/assets/images/flowdiagram_rus/22.png" alt="">
      <p>После того, как вы нажали «Сохранить», система сгенерирует QR-код для регистрации, который можно отсканировать
        с помощью смартфона. </p>
      <img src="@/assets/images/flowdiagram_rus/23.png" alt="">
      <p>14、Покупка билетов на мероприятия: в меню выберите и нажмите «Регистрация дистрибьютера», после чего выберите
        «Купить билеты на мероприятие»; после того как вы выбрали желаемое количество билетов, введите уникальный номер
        дистрибьютера и платёжный пароль, нажмите «Сделать заказ», после чего заказ будет успешно сформирован. </p>
      <img src="@/assets/images/flowdiagram_rus/24.png" alt="">
      <img src="@/assets/images/flowdiagram_rus/25.png" alt="">
      <p>15、Апгрейд и повторные покупки: после того, как вы вошли в приложение, выберите желаемый продукт на главной
        странице или на странице с продукцией, чтобы добавить его в корзину; на странице корзины с покупками по
        умолчанию используются данные текущей учётной записи.</p>
      <img src="@/assets/images/flowdiagram_rus/26.png" alt="">
      <p>Выберите тип заказа как «Апгрейд», после чего вам нужно выбрать необходимый уровень, после чего нажать «Сделать
        заказ» для формирования заказа.</p>
      <img src="@/assets/images/flowdiagram_rus/27.png" alt="">
      <p>Вы также можете выбрать тип заказа «Повторная покупка», нажать «Сделать заказ» после чего заказ будет успешно
        сформирован.</p>
      <img src="@/assets/images/flowdiagram_rus/28.png" alt="">
      <p>После того, как вы нажали «Сделать заказ» необходимо выбрать способ получения товара и ввести платёжный пароль
        для оплаты заказа.</p>
      <img src="@/assets/images/flowdiagram_rus/29.png" alt="">
      <p>После того, как будет произведена оплата, заказ будет успешно зарегистрирован.</p>
      <img src="@/assets/images/flowdiagram_rus/30.png" alt="">
      <p>16、В меню выберите и нажмите «История заказов» чтобы просмотреть историю заказов и историю покупки билетов.</p>
      <img src="@/assets/images/flowdiagram_rus/31.png" alt="">
      <p>Выберите и нажмите «Детали заказа», чтобы просмотреть подробные сведения о заказе.</p>
      <img src="@/assets/images/flowdiagram_rus/32.png" alt="">
      <p>17、Просмотр сетевой структуры: В меню в разделе управления учётной записью вы можете просмотреть спонсорскую
        сеть (структура рекомендателей), а также расположение в древовидной системе (бинарном дереве).</p>
      <img src="@/assets/images/flowdiagram_rus/33.png" alt="">
      <p>Поиск рекомендателя: нажмите, чтобы просмотреть ситуацию рекомендаций дистрибьюторов в системе.</p>
      <img src="@/assets/images/flowdiagram_rus/34.png" alt="">
      <p>Поиск рекомендателя - TREE: Вы можете просмотреть сеть дистрибьюторов, а также личные результаты. </p>
      <img src="@/assets/images/flowdiagram_rus/35.png" alt="">
      <p>Поиск управляющего: Нажмите, чтобы просмотреть ситуацию размещения дистрибьюторов в системе.</p>
      <img src="@/assets/images/flowdiagram_rus/36.png" alt="">
      <p>Поиск управляющего - TREE: Нажмите, чтобы просмотреть размещение дистрибьюторов в древовидной системе.</p>
      <img src="@/assets/images/flowdiagram_rus/37.png" alt="">
      <p>18、Изменение личной информации: В меню выберите данный параметр, чтобы изменить личную информацию, изменить
        текущий пароль для входа в систему, адрес получения товара. После внесения изменений нажмите «Сохранить», чтобы
        данные были успешно изменены.</p>
      <img src="@/assets/images/flowdiagram_rus/40.png" alt="">
      <p>19、Шагомер: Откройте меню, выберите и нажмите на «Шагомер», выберите и нажмите «Разрешить приложению получать
        данные о состоянии мобильного телефона», после чего вы сможете просмотреть динамику шагов.</p>
      <img src="@/assets/images/flowdiagram_rus/41.png" alt="">
      <p>20、Выход из учётной записи: Откройте меню, выберите и нажмите «Выйти из системы» чтобы выйти из учётной
        записи. </p>
      <img src="@/assets/images/flowdiagram_rus/42.png" alt="">
      <p>Или на странице изменения личной информации выберите и нажмите «Выход дистрибьютора» чтобы выйти из учётной
        записи.</p>
      <img src="@/assets/images/flowdiagram_rus/43.png" alt="">
    </div>
  </div>
</template>

<script>
import {uploadTimes} from "@/api/home";
import QRCode from "qrcode";
import {mapGetters} from "vuex";

export default {
  name: "DownloadApp",
  data() {
    return {
      iosOrAn: '',
      BaseUrl: '',
      And_url: '',
      Ios_url: '',
      loading: false,
      show: 1
    }
  },
  computed: {
    ...mapGetters([
      'shoppingList',
      'language',
      'userCode',
      'token',
      'ckCode',
      'username',
      'isAgent',
      'permissions',
      'permission_routes',
      'companyCode'
    ]),
  },
  async created() {
    if (this.language === 'zh_CN') {
      this.show = 1
    } else {
      this.show = 2
    }
    this.loading = true
    this.Ios_url = await this.getDownloadUrl('IOS')
    this.createQrCode(this.Ios_url, 'QRCode_IOS_Download2')
    this.loading = false
  },
  mounted() {
    this.BaseUrl = window.location.href.split('#')[0] + '#/downloadapp?type=Android'
    this.createQrCode(this.BaseUrl, 'QRCode_Android_Download2')
  },
  watch: {
    "language": function (newVal, oldVal) {
      console.log("新值", newVal);
      console.log("旧值", oldVal);
      if (newVal === 'zh_CN') {
        this.show = 1
      } else {
        this.show = 2
      }
    },
    $route: {
      handler: async function (route) {
        const query = route.query
        if (query.type === 'Android') {
          this.And_url = await this.getDownloadUrl('Android')
          let a = document.createElement('a')
          a.href = this.And_url
          a.click();
        }
      },
      immediate: true
    },
  },
  methods: {
    createQrCode(qrUrl, domId) {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 280, //宽
        height: 280, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = document.getElementById(domId);
      // 将获取到的数据(val)画到msg(canvas)上
      QRCode.toCanvas(msg, qrUrl, opts, function (error) {
        if (error) {
          console.log("二维码加载失败", error);
          this.$message.error("二维码加载失败");
        }
      })
    },
    getDownloadUrl(type) {
      return new Promise((resolve, reject) => {
        uploadTimes({'iosOrAn': type}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.flowdiagram_zh {
  p {
    font-size: 20px;
    //text-align: left;
  }
}

.flowdiagram_rus {
  p {
    font-size: 20px;
    //text-align: left;
  }
}

.qrCodeDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around
}

@media only screen and (max-width: 750px) {
  .qrCodeDiv {
    display: flex;
    flex-direction: column;
  }
}
</style>
